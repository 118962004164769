import React from 'react';
import Footer from '../components/footer/Footer';
import HeaderCommon from '../components/header/HeaderCommon';
import Layout from '../components/layout';
import Seo from '../components/seo';
import BreadcrumbSection from '../elements/BreadcrumbSection';

const PrivacyPolicy = () => {
  return (
    <Layout>
      <Seo
        title="Privacy Policy | banco"
        description="This Privacy Policy is used to inform visitors and users regarding our policies with regard to the collection, use, and disclosure of personally identifiable information."
      />
      <main className="main-wrapper">
        <HeaderCommon />

        <BreadcrumbSection
          title="Privacy Policy"
          paragraph={<span style={{ width: '100%' }}>Last updated on 15 November 2021.</span>}
        />

        {/* Privacy Policy */}
        <div className="section section-privacy">
          <div className="container">
            <aside>
              <ul>
                <li>
                  <a className="listHeader" href="#01">
                    01. What information do we collect?
                  </a>
                </li>
                <li>
                  <a className="listHeader" href="#02">
                    02. Your interactions with others{' '}
                  </a>
                </li>
                <li>
                  <a className="listHeader" href="#03">
                    03. How do we store and secure your information?{' '}
                  </a>
                </li>
                <li>
                  <a className="listHeader" href="#04">
                    04. How do we use your Information?{' '}
                  </a>
                </li>
                <li>
                  <a className="listHeader" href="#05">
                    05. Sharing your Information{' '}
                  </a>
                </li>
                <li>
                  <a className="listHeader" href="#06">
                    06. Links to Third Party Websites{' '}
                  </a>
                </li>
                <li>
                  <a className="listHeader" href="#07">
                    07. Transfer of information{' '}
                  </a>
                </li>
                <li>
                  <a className="listHeader" href="#08">
                    08. Tracking technologies{' '}
                  </a>
                </li>
                <li>
                  <a className="listHeader" href="#09">
                    09. Accessing and updating your Information{' '}
                  </a>
                </li>
                <li>
                  <a className="listHeader" href="#10">
                    10. Withdrawal of Consent & Lodging of Complaint{' '}
                  </a>
                </li>
                <li>
                  <a className="listHeader" href="#11">
                    11. Visiting Our Site From Outside Singapore{' '}
                  </a>
                </li>
                <li>
                  <a className="listHeader" href="#12">
                    12. European Union Privacy Rights{' '}
                  </a>
                </li>
                <li>
                  <a className="listHeader" href="#13">
                    13. Changes to our Privacy Policy{' '}
                  </a>
                </li>
              </ul>
            </aside>
            <article>
              <div>
                <h4 className="articleHeader">Introduction</h4>
                <p className="paragraph">
                  This Privacy Policy is used to inform visitors and users regarding our policies
                  with regard to the collection, use, and disclosure of personally identifiable
                  information such as your name, e-mail address, shipping address, phone number,
                  credit card information and other data, whether true or not, that identifies you
                  as an individual ("Personal Data"); and governs the usage of our Site which
                  comprises of
                  <a href="https://www.rootant.com/">https://www.rootant.com/</a>,{' '}
                  <a href="https://www.banco.asia/">https://www.banco.asia/</a>,{' '}
                  <a href="https://www.banco.com.sg/">https://www.banco.com.sg/</a>,{' '}
                  <a href="https://www.bancr.sg/">https://www.bancr.sg/</a>,{' '}
                  <a href="https://www.rabc.sg/">https://www.rabc.sg/</a> and its related
                  sub-domains, social media pages and any related applications (including mobile
                  applications) and includes their respective internet-based services, features,
                  content, and functionality (the “Services”). By using the Site, accepting the
                  Terms of Use, and/or making any use of the Services through the Site, you also
                  agree to this Privacy Policy. If you do not agree to this Privacy Policy, you must
                  not use the Site. The terms "we," "us," "our," refer to TKNEST PTE. LTD., a wholly
                  owned subsidiary of RootAnt Global Pte Ltd and our Affiliates. We are committed to
                  protecting your privacy and complying with the Personal Data Protection Act 2012
                  of Singapore (“PDPA”). Consequently, we will only collect, record, hold, store,
                  disclose, transfer and use your Personal Data as outlined in this Privacy Policy.
                </p>
              </div>
              <div>
                <h4 className="articleHeader">
                  <span id="01">01.</span>
                  What information do we collect?
                </h4>
                <p className="paragraph">
                  We may collect and process the following information from you:
                </p>
                <ul>
                  <li className="paragraph">
                    information (such as your e-mail address, telephone number, gender and age) that
                    you provide by completing forms on the Site, including if you register as a user
                    of the Site, receive our newsletter, upload or submit any material via the Site,
                    request any information or enter into any promotion we provide;
                  </li>
                  <li className="paragraph">
                    in connection with your account, your log-in and password details;
                  </li>
                  <li className="paragraph">
                    details of any transactions made by you through the Site;
                  </li>
                  <li className="paragraph">
                    usage of the features, functions and tools available on our Site, for example,
                    by creating listings and credentials, interacting with other users through the
                    Site’s chat feature, or transacting with other users;
                  </li>
                  <li className="paragraph">
                    communications you send to us, for example to report a problem or to submit
                    queries, concerns or comments regarding the Site and its content;
                  </li>
                  <li className="paragraph">
                    information from surveys, promotions and competitions that we may, from time to
                    time, run on the Site, if you choose to respond to, or participate in them;
                  </li>
                  <li className="paragraph">
                    details of your visits to the Site, the resources you access and any data you
                    download;
                  </li>
                  <li className="paragraph">
                    technical information from your communications device relating to the Services
                    you receive;
                  </li>
                  <li className="paragraph">
                    information that you post in the form of comments or contributions to
                    discussions;
                  </li>
                  <li className="paragraph">information that does not identify you.</li>
                </ul>
                <p className="paragraph">
                  You are under no obligation to provide the information to us. However, if you
                  choose to withhold the information or to revoke permission for us to receive the
                  information, we may be unable to provide certain aspects of our Services to you.
                </p>
                <p className="paragraph">
                  <br />
                  Third Parties
                </p>
                <p className="paragraph">
                  By providing us with any information relating to a third party (e.g. information
                  of your spouse, children, parents, customers and/or employees), you represent to
                  us that you have obtained the consent of the third party to provide us with their
                  information, and have ensured that the third party is aware of, understands and
                  accepts the terms of this Privacy Policy for the respective purposes.
                </p>
                <p className="paragraph">
                  <br />
                  Other Data
                </p>
                <p className="paragraph">
                  We want to inform you that whenever you use our Services, data may be gathered
                  through tracking of your online Service usage. For example, we may capture the IP
                  address of the device you use to connect to the online service, the type of
                  operating system and browser you use, and information about the site you came
                  from, the parts of our online service you access, and the site you visit next. We
                  or our third-party service providers may also use cookies, web beacons or other
                  technologies to collect and store other information about your visit to, or use
                  of, the Services. In addition, we may later associate the usage and other
                  information we collect online with Personal Data about you. In case of an error in
                  the Site, we may collect data and information (through third party products) on
                  your phone called Log Data. This Log Data may include information such as your
                  device Internet Protocol (“IP”) address, device name, operating system version,
                  the configuration of the mobile application when utilizing our Service, the time
                  and date of your use of the Service, and other statistics.
                </p>
                <p className="paragraph">
                  <br />
                  Legal Age
                </p>
                <p className="paragraph">
                  These Services do not address anyone under the age of 18. We do not knowingly
                  collect Personal Data personally identifiable information from persons under 18
                  years old. In the case we discover that a person under 18 has provided us with
                  Personal Data, we will immediately delete this from our servers. If you are a
                  parent or guardian and you are aware that your child has provided us with Personal
                  Data, please contact us so that we will be able to carry out the necessary
                  actions.
                </p>
              </div>
              <div>
                <h4 className="articleHeader">
                  <span id="02">02.</span>
                  Your interactions with others
                </h4>
                <p className="paragraph">
                  The Site offers features which allow users to connect and communicate with
                  participating financial institutions and/or with each other to complete a
                  transaction. Please use common sense and exercise good judgement when sharing
                  information.
                </p>
                <p className="paragraph">
                  We generally do not monitor or remove the information which you share and such
                  information may remain available on our Services after your account has been
                  deactivated, terminated or is otherwise closed. You are responsible for the
                  information you choose to share on the Site.
                </p>
              </div>
              <div>
                <h4 className="articleHeader">
                  <span id="03">03.</span>
                  How do we store and secure your information?
                </h4>
                <p className="paragraph">
                  We maintain control of your information and protect your information using
                  technical and administrative security measures to reduce the risks of loss,
                  misuse, unauthorised access, disclosure and alteration.
                </p>
                <p className="paragraph">
                  We have procedures in place to manage any suspected breach of your information and
                  will notify you and any applicable governmental authority of any actual or
                  suspected breaches of your Personal Data where we are required by contract or law
                  to do so.
                </p>
              </div>
              <div>
                <h4 className="articleHeader">
                  <span id="04">04.</span>
                  How do we use your Information?
                </h4>
                <p className="paragraph">
                  We may use the information collected and received from you for the following
                  purposes:
                </p>
                <ul>
                  <li className="paragraph">
                    Onboard and process your request for loan application(s) with participating
                    financial institutions;
                  </li>
                  <li className="paragraph">
                    Verify your identity, update our records and generally maintain your account
                    with us;
                  </li>
                  <li className="paragraph">
                    Provide the Services and customise the content shown to you;
                  </li>
                  <li className="paragraph">
                    Develop, operate, improve, deliver and maintain the Services;
                  </li>
                  <li className="paragraph">
                    Respond to your queries, communicate with you and to resolve complaints;
                  </li>
                  <li className="paragraph">
                    Carry out polls, surveys, analysis and research on how the Services are being
                    used and how we can improve them;
                  </li>
                  <li className="paragraph">
                    Update you on the Services (e.g. changes to the Services, new offers, new chats,
                    new feedback, new comments). These updates will be sent by email and/or through
                    push notifications and you may choose to unsubscribe from some of these updates
                    at any time;
                  </li>
                  <li className="paragraph">
                    Send you marketing and/or promotional materials on the latest Services and tools
                    offered or provided by us or by carefully selected third parties. These updates
                    will be sent by email and/or through push notifications and you can choose to
                    unsubscribe from these updates;
                  </li>
                  <li className="paragraph">
                    Send you service-related messages including, without limitation, a
                    welcome/confirmation email when you register your account and to enforce our
                    Terms of Use and other policies, applicable legal requirements, relevant
                    industry standards and contractual obligations. These messages are not
                    promotional in nature. These messages will be sent by email and/or through push
                    notifications and you may not opt-out of receiving service-related messages from
                    us, unless you deactivate your account.
                  </li>
                </ul>
                <p></p>
              </div>
              <div>
                <h4 className="articleHeader">
                  <span id="05">05.</span>
                  Sharing your Information
                </h4>
                <p className="paragraph">
                  We may share information about you in the following ways:
                </p>
                <ul>
                  <li className="paragraph">
                    Your Personal Data is made available to participating financial institutions for
                    approval of your loan request(s).
                  </li>
                  <li className="paragraph">
                    We may share the information with our employees, Affiliates and relevant third
                    party service providers who assist us in performing certain aspects of the
                    Services on our behalf, such as processing transactions, fulfilling requests for
                    information, receiving and sending communications, updating marketing lists,
                    analysing data, providing support services or in other tasks. Such service
                    providers will only have access to your Personal Data to the extent necessary to
                    perform their functions.
                  </li>
                  <li className="paragraph">
                    We may share the information with our potential partners, investors and other
                    parties with a view to a potential business partnership, collaboration, joint
                    venture or otherwise in furtherance of our business.
                  </li>
                  <li className="paragraph">
                    We may disclose your Personal Data if required to do so by law or if we believe
                    that such action is necessary to prevent fraud or crime or to protect our
                    business or the rights, property or personal safety of any person.
                  </li>
                </ul>
                <p className="paragraph">
                  We respect your privacy and will not sell your Personal Data to any third party
                  such as list brokers, mail-order businesses, or telemarketers. We may disclose
                  data and aggregate statistics about users of our Services and sales to prospective
                  partners, advertisers, sponsors and other reputable third parties in order to
                  describe the Services, deliver targeted advertisements or for other lawful
                  purposes, but these data and statistics will not include information which can be
                  used to identify you.
                </p>
              </div>
              <div>
                <h4 className="articleHeader">
                  <span id="06">06.</span>
                  Links to Third Party Websites
                </h4>
                <p className="paragraph">
                  We may provide links to websites that are owned or operated by other companies
                  ("third-party websites"). When you use a link online to visit a third-party
                  website, you will be subject to that website’s privacy and security practices,
                  which may differ from ours. You should familiarize yourself with the privacy
                  policy, terms of use and security practices of the linked third-party website
                  before providing any information on that website.
                </p>
              </div>
              <div>
                <h4 className="articleHeader">
                  <span id="07">07.</span>
                  Transfer of information
                </h4>
                <p className="paragraph">
                  By using the Services, you authorise us to use your information in Singapore and
                  other countries where we operate for the purposes mentioned above. We will ensure
                  that your information is transferred in accordance with this Privacy Policy and
                  protected in accordance with applicable laws on personal data protection
                  (including, but not limited to, the Personal Data Protection Act 2012 of
                  Singapore).
                </p>
              </div>
              <div>
                <h4 className="articleHeader">
                  <span id="08">08.</span>
                  Tracking technologies
                </h4>
                <p className="paragraph">
                  The Site uses tracking technologies on our Services. These enable us to understand
                  how you use our Services which, in turn, helps us to provide and improve them.
                  However, the tracking technologies do not access, receive or collect information
                  which can be used to identify you. Below are some examples of the tracking
                  technologies used on our Services:
                </p>
                <ul>
                  <li className="paragraph">
                    Cookies. A cookie is a small data file sent from a website to your browser that
                    is stored on your device. Cookies are used for a number of purposes, including
                    to display the most appropriate content based on your interests and activity on
                    our Services, estimate and report our Services’ total audience size and traffic,
                    and conduct research to improve our Services. You can configure your device's
                    settings to reflect your preference to accept or reject cookies. If you reject
                    all cookies, you will not be able to take full advantage of our Services.
                  </li>
                </ul>
                <p></p>
              </div>
              <div>
                <h4 className="articleHeader">
                  <span id="09">09.</span>
                  Accessing and updating your Information
                </h4>
                <p className="paragraph">
                  You can update your information through your account profile settings. If you
                  believe that Personal Data we hold about you is incorrect, incomplete or
                  inaccurate, you may request us to amend it.
                </p>
                <p className="paragraph">
                  Additionally, you may request access to any Personal Data that we hold about you
                  at any time by contacting us at{' '}
                  <a href="mailto:support@banco.com.sg">support@banco.com.sg</a>. Where we hold
                  information that you are entitled to access, we will try to provide you with
                  suitable means of accessing it (for example, by mailing or emailing it to you). We
                  may charge you a fee to cover our administrative and other reasonable costs in
                  providing the information to you. We will not charge for simply making the request
                  and will not charge for making any corrections to your Personal Data.
                </p>
                <p className="paragraph">
                  There may be instances where we cannot grant you access to the Personal Data that
                  we hold. For example, we may need to refuse access if granting access would
                  interfere with the privacy of others or if it would result in a breach of
                  confidentiality. If that happens, we will give you written reasons for any
                  refusal.
                </p>
              </div>
              <div>
                <h4 className="articleHeader">
                  <span id="10">10.</span>
                  Withdrawal of Consent & Lodging of Complaint
                </h4>
                <p className="paragraph">
                  To make a request to withdraw consent previously given, please contact us at{' '}
                  <a href="mailto:support@banco.com.sg">support@banco.com.sg</a>. All requests to
                  have personal data removed from Our databases or physical storage facilities will
                  be entertained and performed within Our reasonable abilities.
                </p>
              </div>
              <div>
                <h4 className="articleHeader">
                  <span id="11">11.</span>
                  Visiting Our Site From Outside Singapore
                </h4>
                <p className="paragraph">
                  This Privacy Policy is intended to cover collection and use of information on our
                  Site within Singapore. If you are visiting our Site from outside Singapore, please
                  be aware that your information may be transferred to, stored and processed in
                  Singapore where our servers are located and our central database is operated. The
                  data protection and other laws of Singapore and other countries might not be as
                  comprehensive as those in your country. Please be assured that we seek to take
                  reasonable steps to ensure that your privacy is protected. By using our Services,
                  you understand that your information may be transferred to our facilities and
                  those third parties with whom we share it as described in this Privacy Policy.
                </p>
              </div>
              <div>
                <h4 className="articleHeader">
                  <span id="12">12.</span>
                  European Union Privacy Rights
                </h4>
                <p className="paragraph">
                  Under the General Data Protection Regulation (Regulation EU 2016/679) (also known
                  as GDPR), if you are an individual protected by the GDPR you may have certain
                  rights as a data subject. To request information about or avail yourself of those
                  rights, please send an email to{' '}
                  <a href="mailto:support@banco.com.sg">support@banco.com.sg</a> with "GDPR Request"
                  in the subject line. In the email please describe, with specificity, the GDPR
                  right you are requesting assistance with. Please note additional information may
                  be requested prior to initiation of a request and that we reserve the right to
                  charge a fee with respect to certain requests. Upon completion of our review, you
                  will be notified if your request has been granted, denied, or exemptions apply.
                </p>
              </div>
              <div>
                <h4 className="articleHeader">
                  <span id="13">13.</span>
                  Changes to our Privacy Policy
                </h4>
                <p className="paragraph">
                  We will occasionally update this Privacy Policy to reflect changes in our
                  practices and Services. If we make any material changes in the way we collect,
                  use, and/or share personal data that may impact you, we will notify you by sending
                  an e-mail to the e-mail address you most recently provided us in your account,
                  profile or registration (unless we do not have such an e-mail address), and/or by
                  prominently posting notice of the changes on our Site. We recommend that you check
                  our Site from time to time to inform yourself of any changes in this Privacy
                  Policy or any of our other policies.
                </p>
              </div>
            </article>
          </div>
        </div>
        <Footer />
      </main>
    </Layout>
  );
};

export default PrivacyPolicy;
